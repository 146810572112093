import { render, staticRenderFns } from "./RectWithDotted.vue?vue&type=template&id=9ad05edc&scoped=true&"
import script from "./RectWithDotted.vue?vue&type=script&lang=js&"
export * from "./RectWithDotted.vue?vue&type=script&lang=js&"
import style0 from "./RectWithDotted.vue?vue&type=style&index=0&id=9ad05edc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_0aadd186e864c83cab1284bae568d6ae/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ad05edc",
  null
  
)

export default component.exports