import { render, staticRenderFns } from "./MSection_1.vue?vue&type=template&id=046b4435&scoped=true&"
import script from "./MSection_1.vue?vue&type=script&lang=js&"
export * from "./MSection_1.vue?vue&type=script&lang=js&"
import style0 from "./MSection_1.vue?vue&type=style&index=0&id=046b4435&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_0aadd186e864c83cab1284bae568d6ae/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "046b4435",
  null
  
)

export default component.exports